import React from 'react';
import { Link } from 'components';
import logo from '../../images/logo.png';
import './styles.scss';

const Header = () => (
  <header className="header">
    <div className="wrapper header-container">
      <div className="left-title">
        <h1>
          <Link to="/">Rescue.</Link>
        </h1>
      </div>
      <div className="logo-container">
        <img src={logo} alt="exd logo" className="logo" />
      </div>
      <div className="right-title">
        <h2>Support.</h2>
      </div>
    </div>
  </header>
);

export default Header;
