import React from 'react';
import { Link } from 'components';
import logo from '../../images/logo.png';
import './styles.scss';

const Footer = () => (
  <footer className="footer">
    <div className="wrapper">
      <div className="left-title">
        <h1>
          <Link to="/">Rescue.</Link>
        </h1>
      </div>
      <div className="logo-container">
        <img src={logo} alt="exd logo" className="logo" />
      </div>
      <div className="sitemap">
        <span className="legal">© Copyright {new Date().getFullYear()}</span>
      </div>
    </div>
  </footer>
);

export default Footer;
