module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Rescue - Germs Gone. Jobs Created', // Default Site Title used for SEO & PWA
  description: 'This is a critical response register for hand sanitiser for the Australian community', // Default Site Decription used for SEO
  siteName: 'Rescue', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/logo-1024.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Rescue', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Rescue', // Author for schemaORGJSONLD
  themeColor: '#F8E71C',
  backgroundColor: '#000000',

  twitter: '', // Twitter Username
  googleTagManagerId: '',
};
