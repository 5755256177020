import React from 'react';
import { Button } from 'components';
import logo from '../../images/logo.png';
import './styles.scss';

const TextBlock = props => {
  const { tagline, title, content, buttonText, buttonTo } = props;

  return (
    <section className="text-block-container">
      <div className="wrapper">
        <h5 className="text-block-tagline">{tagline}</h5>
        <div className="text-block-title-container">
          <img src={logo} alt="Exr logo" className="text-block-logo" />
          <h3 className="text-block-title">{title}</h3>
        </div>
        <TextBlockContent content={content} />
        {buttonText && <Button to={buttonTo}>{buttonText}</Button>}
      </div>
    </section>
  );
};

const TextBlockContent = ({ content }) => {
  if (!content) return null;

  if (Array.isArray(content)) {
    return content.map(text => <p className="text-block-content">{text}</p>);
  }

  return <p className="text-block-content">{content}</p>;
};

export default TextBlock;
